import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//import { TextMaskModule } from 'angular2-text-mask'; TODO
import { InputMaskModule } from 'primeng/inputmask';
import { TabViewModule } from 'primeng/tabview';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';

import { LogInComponent } from './login/login.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ProviderRegistrationComponent } from './registration/provider-registration.component';
import { RegistrationInformationComponent } from './registration/information/information.component';
import { EulaComponent } from './registration/eula/eula.component';
import { SubmitComponent } from './registration/submit/submit.component';
import { ThankYouComponent } from './registration/thank-you/thank-you.component';

import { PortalUIComponentsModule } from '@caloptima/portal-ui-components';

import { routedComponents, UserRoutingModule } from './user-routing';;
import { SetupPasswordComponent } from './setup-password/setup-password.component'

import { UserHeaderComponent } from './user-header/user-header.component'
import { AppComponentsModule } from '../app-components/app-components.module';
import { UserFooterComponent } from './user-footer/user-footer.component';
import { CheckStatusComponent } from './check-status/check-status.component';
import { UnsupportedBrowserComponent } from './unsupported-browser/unsupported-browser.component';

@NgModule({
  declarations: [
    routedComponents,
    LogInComponent,
    LoggedOutComponent,
    ForgotPasswordComponent,
    ProviderRegistrationComponent,
    RegistrationInformationComponent,
    EulaComponent,
    SubmitComponent,
    ThankYouComponent,
    SetupPasswordComponent,
    UserHeaderComponent,
    UserFooterComponent,
    CheckStatusComponent,
    UnsupportedBrowserComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    //TextMaskModule, TODO
    InputMaskModule,
    UserRoutingModule,
    TabViewModule,
    MessageModule,
    DialogModule,
    TooltipModule,
    PortalUIComponentsModule,
    AppComponentsModule
  ],
  providers: [
    //{ provide: UserAdminService, useClass: MockUserAdminService },
  ]
})
export class UserModule { }
